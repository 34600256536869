import React from "react";

const CertificationContainer = () => (
  <div>
    <div className="w-full max-w-md mx-auto pb-5 pr-2">
      <img
        src={`${process.env.PUBLIC_URL}/moneyterviewNewLogo/돈터뷰_기본 로고.png`}
        alt="Logo"
        className="w-full h-auto"
      />
    </div>
  </div>
);

export default CertificationContainer;
