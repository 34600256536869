import React from "react";
import CertificationContainer from "./CertificationContainer";
import { ErrorOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  const privacyClick = () => {
    navigate("/privacyPolicyNotice");
  };

  // 노후 준비 설문 조사
  const retirementSurvey = () => {
    navigate("/question");
  };

  // 허세 계산기 설문조사
  const vanitySurvey = () => {
    navigate("/vanity");
  };

  return (
    <div>
      <CertificationContainer />
      <div className="w-full max-w-md p-7 mx-auto bg-white rounded-lg shadow-xl">
        <h2 className="mb-4 text-2xl font-bold text-gray-800 text-center">원하는 설문조사를 선택하세요</h2>
        <div className="flex flex-col">
          <div
            className="bg-purple-100 text-center py-8 rounded-lg h-auto shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer"
            onClick={retirementSurvey}
          >
            <p className="text-purple-700 font-semibold text-2xl">노후준비 설문조사</p>
          </div>
          <div className="pt-5"></div>
          <div
            className="bg-purple-100 text-center py-8 w-full h-auto rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer"
            onClick={vanitySurvey}
          >
            <p className="text-purple-700 font-semibold text-2xl">허세 계산기</p>
          </div>
        </div>
      </div>

      <div className="text-center mt-4 text-blue-800 flex items-center justify-center relative">
        <ErrorOutline
          className="text-blue-800 mr-2 cursor-pointer hover:text-blue-600 focus:text-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none rounded"
          style={{ fontSize: "20px", verticalAlign: "middle" }}
          onClick={privacyClick}
        />
        <div
          className="mt-1 cursor-pointer hover:text-blue-600 focus:text-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none rounded"
          onClick={privacyClick}
        >
          개인 정보 처리 방침
        </div>
      </div>
    </div>
  );
};

export default Login;
