import React, { useEffect, useContext } from "react";

const SubmitSummary = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white w-full max-w-4xl p-6 mx-auto rounded-lg shadow-xl max-h-[520px] sm:max-h-none flex flex-col">
          <h2 className="text-2xl font-bold text-gray-800 text-center">
            - 재무 상태 (계산 정보) 분석 설문조사 이용정보 동의서 -
          </h2>
          <div className="mb-6 max-w-full mx-auto flex-1 overflow-y-auto">
            <div className="bg-gray-100 p-4 rounded-md text-lg mt-5 h-full">
              본 서비스는 <strong>개인정보 보호법</strong> 및 관련 법령에 따라 고객님의 소중한 개인정보를 보호하며,
              다음의 목적을 위해 개인정보를 수집 및 이용합니다:
              <br />
              <br />
              <ul className="list-disc list-inside">
                <li>회원가입 및 서비스 이용자 식별</li>
                <li>서비스 제공 및 맞춤형 서비스 제공</li>
                <li>고객 상담 및 서비스 관련 공지사항 전달</li>
                <li>서비스 개선 및 분석을 위한 통계 자료 생성</li>
                <li>부정 이용 방지 및 법적 분쟁 대응</li>
              </ul>
              <br />
              <strong>수집하는 개인정보 항목</strong>
              <br />
              필수 항목: 이름, 이메일 주소, 휴대폰 번호, 로그인 정보
              <br />
              선택 항목: 프로필 사진
              <br />
              <br />
              제공하신 정보는 원칙적으로 <strong>개인정보 수집 및 이용 목적</strong>이 달성된 후 지체 없이 파기됩니다.
              단, 관련 법령에 따라 계약 관련 기록은 5년, 소비자 분쟁 기록은 3년간 보관됩니다.
              <br />
              <br />
              회사는 이용자의 동의 없이는 개인정보를 <strong>제3자에게 제공하지 않으며</strong>, 필요한 경우 고객님의
              동의를 받아 외부에 개인정보 처리 업무를 위탁할 수 있습니다.
              <br />
              <br />
              <strong>개인정보는 보유 기간이 경과하거나 처리 목적이 달성되면 지체 없이 안전하게 파기</strong>
              됩니다.
              <br />
              <br />
              이용자는 개인정보의 열람, 수정, 삭제, 처리 정지 요구를 할 수 있으며, 동의하지 않을 권리가 있습니다. 단,
              필수 항목에 대한 동의를 거부하실 경우 서비스 이용에 제한이 있을 수 있습니다.
            </div>
          </div>
          <button
            className="hidden sm:block w-full px-4 py-2 text-base text-white transition-colors duration-200 bg-blue-500 rounded-lg hover:bg-blue-600"
            onClick={onClose}
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubmitSummary;
